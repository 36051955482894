<template>
  <div>
    <div v-if="permissionCheck('read-data-usage-statistics')">
      <v-card-text>
        <div class="main-data-div">
          <div class="icon-div">
            <v-icon
              color="#FF3700"
              size="60"
            >
              mdi-chart-donut
            </v-icon>
          </div>
          <div class="icon-div">
            <p class="my-p-style">
              DATA USAGE
            </p>
          </div>
        </div>
        <div class="main-data-div">
          <div class="data-div">
            <div class="subtitle-style">
              This Month
            </div>
            <div v-if="showDataUsage">
              <span
                class="text-h3 font-weight-black"
              > {{ dataUsageStatistics.organization.period_usage | dataConverter }}</span>
              <strong>GB</strong>
            </div>
          </div>
          <div class="data-div">
            <div class="subtitle-style">
              Total
            </div>
            <div v-if="showDataUsage">
              <span
                class="text-h3 font-weight-black"
              > {{ dataUsageStatistics.organization.total_usage | dataConverter }}</span>
              <strong>GB</strong>
            </div>
          </div>
        </div>
        <v-sheet color="transparent">
          <v-sparkline
            :smooth="16"
            :gradient="['#37474F', '#90A4AE', '#FF8A65']"
            :line-width="6"
            auto-draw
            :auto-draw-duration="5000"
            auto-draw-easing="ease"
            stroke-linecap="round"
            :value="value"
          />
        </v-sheet>
      </v-card-text>
    </div>
    <div v-else>
      <v-card-text>
        <div class="main-data-div">
          <div class="icon-div">
            <v-icon
              color="#FF3700"
              size="60"
            >
              mdi-chart-donut
            </v-icon>
          </div>
          <div class="icon-div">
            <p class="my-p-style">
              DATA USAGE
            </p>
          </div>
          <v-alert
            text
            prominent
            type="warning"
            color="red"
            border="left"
            class="authorization-alert"
          >
            You are not authorized to perform this action. Please contact your administrator.
          </v-alert>
        </div>
        <div class="main-data-div">
          <div class="data-div">
            <div class="subtitle-style">
              This Month
            </div>
            <div>
              <span
                class="text-h3 font-weight-black"
              >--</span>
              <strong>GB</strong>
            </div>
          </div>
          <div class="data-div">
            <div class="subtitle-style">
              Total
            </div>
            <div>
              <span
                class="text-h3 font-weight-black"
              >--</span>
              <strong>GB</strong>
            </div>
          </div>
        </div>
        <v-sheet color="transparent">
          <v-sparkline
            :smooth="16"
            :gradient="['#37474F', '#90A4AE', '#FF8A65']"
            :line-width="6"
            auto-draw
            :auto-draw-duration="5000"
            auto-draw-easing="ease"
            stroke-linecap="round"
            :value="value"
          />
        </v-sheet>
      </v-card-text>
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone';
import global from 'src/mixins/global';

export default {
  name: 'DashBoardDataUsage',
  filters: {
    dataConverter (data) {
      return (Number(data)).toFixed(2);
    },
  },
  mixins: [global],
  data () {
    return {
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
    };
  },
  computed: {
    dataUsageStatistics () {
      return this.$store.getters['dashboard/getDataUsageStatistics'];
    },
    showDataUsage () {
      if (Object.keys(this.dataUsageStatistics).length > 0) {
        return true;
      }
      return false;
    },
  },
  mounted () {
    this.permissionCheck('read-data-usage-statistics') && this.getDataUsageStatistics();
  },
  methods: {
    convertToGmt (date) {
      return moment.utc(date).format();
    },
    async getDataUsageStatistics () {
      const startOfMonth = moment().startOf('month');
      const currentDateTime = moment();
      await this.$store.dispatch('dashboard/fetchDataUsageStatistics', {
        params: {
          from: this.convertToGmt(startOfMonth),
          to: this.convertToGmt(currentDateTime),
        },
      });
    },
  },
};
</script>
<style scoped>
.header-style {
  text-align: center;
}
.my-p-style {
  font-size: 20px;
  font-weight: bold;
  color:#37474F;
  font-family: 'Times New Roman', Times, serif;
}
.main-data-div {
  width: 100%;
  text-align:center;
}
.data-div {
  width: 100px;
  display: inline-block;
  margin: 10px;
}
.icon-div {
  display: inline-block;
  margin: 10px;
}
.subtitle-style {
  color: #37474F;
  font-size: 12px;
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  margin-left: 20px;
}
</style>
