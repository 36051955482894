<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-view-dashboard
      </v-icon> Signage Overview
    </h2>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        v-if="isSwiftCheckinEnabled"
        class="text-right"
      >
        <v-menu
          bottom
          left
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="#FF3700"
              v-on="on"
            >
              Projects
              <v-icon
                right
              >
                mdi-arrow-down-drop-circle
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, i) in projects"
              :key="i"
              @click="selectedProject(item)"
            >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <dash-board-data-usage />
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <v-date-picker
            v-model="picker"
            year-icon="mdi-calendar-blank"
            prev-icon="mdi-skip-previous"
            next-icon="mdi-skip-next"
            color="#FF3700"
            header-color="#37474F"
            elevation="15"
            class="my-calendar-style"
          />
        </v-col>
      </v-row>
      <dash-board-status-count />
      <v-row>
        <v-col
          cols="12"
          lg="4"
        >
          <dash-board-left-chart />
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <dash-board-middle-chart />
        </v-col>
        <v-col
          cols="12"
          lg="4"
        >
          <dash-board-right-chart />
        </v-col>
      </v-row>
    </v-card>
    <br>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import dashboardData from 'src/data/dashboard-data';
  import ModuleHelper from 'src/helpers/module-helper';
  import constants from 'src/constants';
  import DashBoardLeftChart from 'src/views/dashboard/component/dashboards/DashBoardLeftChart';
  import DashBoardMiddleChart from 'src/views/dashboard/component/dashboards/DashBoardMiddleChart';
  import DashBoardRightChart from 'src/views/dashboard/component/dashboards/DashBoardRightChart';
  import DashBoardStatusCount from 'src/views/dashboard/component/dashboards/DashBoardStatusCount';
  import DashBoardDataUsage from 'src/views/dashboard/component/dashboards/DashBoardDataUsage';

  export default {
    name: 'DashboardDashboard',
    components: {
      DashBoardLeftChart,
      DashBoardMiddleChart,
      DashBoardRightChart,
      DashBoardStatusCount,
      'centre-spinner': spinner,
      DashBoardDataUsage,
    },

    data () {
      return {
        picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        showChart: true,
        loading: true,
        helper: new ModuleHelper(),
      };
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true) {
          vm.$router.push({ name: 'Product Payment Plans' });
        } else {
          next();
        }
      });
    },

    computed: {
      getOrgSettings () {
        return this.$store.getters['orgsettings/getOrgSettings'];
      },
      isSwiftCheckinEnabled () {
        return this.helper.isModuleExist(constants.MODULE_SWIFT_CHECK_IN);
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Product Payment Plans' });
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.$store.dispatch('orgsettings/fetchOrgSettings');
      this.loading = false;
    },

    methods: {
      complete (index) {
        this.list[index] = !this.list[index];
      },
      getDetails (type) {
        var module = constants.MODULE_BANNER;
        if (this.helper.isModuleExist(constants.MODULE_SWIFT_CHECK_IN)) {
          module = constants.MODULE_SWIFT_CHECK_IN;
        }

        return dashboardData[module][type];
      },

      getTileDetails (tileNumber) {
        var module = constants.MODULE_BANNER;
        if (this.helper.isModuleExist(constants.MODULE_SWIFT_CHECK_IN) === true) {
          module = constants.MODULE_SWIFT_CHECK_IN;
        }

        return dashboardData[module][tileNumber];
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-calendar-style {
  margin-right: 20px;
  margin-top: 10px;
  float: right;
}
.my-carousel-style {
  align-items: center;
  object-position: center;
  text-align: center;
}
</style>
