<template>
  <div>
    <weekly-banner-count-chart v-if="showHistoryReporting" />
    <banner-status-chart v-if="showCurrentReporting" />
  </div>
</template>
<script>
  import BannerStatusChart from './charts/BannerStatusChart';
  import WeeklyBannerCountChart from './charts/WeeklyBannerCountChart.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';

  export default {
    name: 'DashBoardMiddleChart',
    components: {
      BannerStatusChart,
      WeeklyBannerCountChart,
    },
    data () {
      return {
        helper: new ModuleHelper(),
      };
    },
    computed: {
      showCurrentReporting () {
        return this.helper.isSubModuleExist(Constants.MODULE_DASHBOARD, Constants.SUB_MODULE_CURRENT_REPORTING);
      },
      showHistoryReporting () {
        return this.helper.isSubModuleExist(Constants.MODULE_DASHBOARD, Constants.SUB_MODULE_HISTORY_REPORTING);
      },
    },
  };
</script>
