<template>
  <base-chart-card color="#2E7D32">
    <template v-slot:heading>
      <base-bar-chart
        v-if="showChart"
        :chart-data="dataCollection"
        :options="options"
        style="max-height: 150px;"
      />
    </template>
    <template v-slot:reveal-actions>
      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            color="info"
            icon
            v-on="on"
          >
            <v-icon
              color="info"
            >
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <span>Refresh</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            light
            icon
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <span>Change Date</span>
      </v-tooltip>
    </template>

    <h3 class="my-card-title-style">
      {{ middleChartDescription.heading }}
    </h3>

    <p class="my-card-subtitle-style">
      {{ middleChartDescription.subHeading }}
    </p>
    <p
      v-if="noDataExist"
      class="alert-style"
    >
      No Data to Display.
    </p>
    <template v-slot:actions>
      <v-icon
        class="mr-1"
        small
        dense
      >
        mdi-clock-outline
      </v-icon>
      <span class="my-updation-text-style">Updated {{ middleChartDescription.updatedAt | diffHumans }}</span>
    </template>
  </base-chart-card>
</template>
<script>
  import moment from 'moment';
  import { isDataExist } from 'src/helpers/chart-data-helper';

  export default {
    name: 'WeeklyBannerCountChart',
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
    },
    data: () => ({
      label: 'Banner Count',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: '#FBD6E3',
            fontsize: 14,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: '#FBD6E3',
              stepSize: 2,
            },

          }],
          xAxes: [{
            ticks: {
              fontColor: '#FBD6E3',
            },
          }],
        },
      },
    }),
    computed: {
      chartData () {
        const chartData = this.$store.getters['dashboard/getMiddleChart'];
        return chartData.sort(function (a, b) {
          return a.id - b.id;
        });
      },
      middleChartDescription () {
        return this.$store.getters['dashboard/getMiddleChartDescription'];
      },
      dataExist () {
        return this.chartData.length > 0;
      },
      showChart () {
        return Object.keys(this.dataCollection).length > 0;
      },
      dataCollection () {
        if (this.dataExist === false) {
          return {};
        }
        return this.generateDataCollection();
      },
      noDataExist () {
        const dataExist = isDataExist(this.chartData);
        if (dataExist === true) {
          return false;
        }
        return true;
      },
    },
    async mounted () {
      await this.$store.dispatch('digiboards/fetchDigiBoards');
      this.generateMiddleChart();
      this.generateMiddleChartDescription();
    },
    methods: {
      convertToGmt (date) {
        return moment(date).utc();
      },
      generateMiddleChartDescription () {
        const showLastWeekFirstDay = moment().subtract(1, 'weeks').startOf('week').format('DD-MM-YYYY');
        const showLastWeekLastDay = moment().subtract(1, 'weeks').endOf('week').format('DD-MM-YYYY');
        this.$store.dispatch('dashboard/fetchMiddleChartDescription', {
          heading: 'Weekly Banners',
          subHeading: `Banners published between ${showLastWeekFirstDay} to ${showLastWeekLastDay}`,
          updatedAt: moment(),
        });
      },
      getQuery (start, end) {
        return {
          query: {
            index: this.getDigiBoardIndex(),
            size: 0,
            body: {
              query: {
                range: {
                  happendAt: {
                    gte: this.convertToGmt(start),
                    lte: this.convertToGmt(end),
                  },
                },
              },
              aggs: {
                attribCount: {
                  terms: {
                    field: 'message.banner_id.keyword',
                  },
                },
              },
            },
          },
        };
      },
      getDigiBoardIndex () {
        const digiBoards = this.$store.getters['digiboards/getDigiBoards'];
        return digiBoards[0].external_id.toLowerCase();
      },
      generateMiddleChart () {
        const lastWeekLastDay = moment().subtract(1, 'weeks').endOf('week');
        let start = moment().subtract(1, 'weeks').startOf('week').clone();
        for (let i = 1; i <= 7; i++) {
          const end = start.clone().add(1, 'day');
          if (end > lastWeekLastDay) {
            this.$store.dispatch('dashboard/fetchMiddleChartData', { query: this.getQuery(start, lastWeekLastDay), label: `${start.format('ddd')}`, id: i });
            break;
          }
          this.$store.dispatch('dashboard/fetchMiddleChartData', { query: this.getQuery(start, end), label: `${start.format('ddd')}`, id: i });
          start = end.clone();
        }
      },
      generateDataCollection () {
        const actionCount = [];
        const labels = [];
        for (const result of this.chartData) {
          actionCount.push(result.count);
          labels.push(result.label);
        }
        return {
          labels: labels,
          datasets: [
            {
              data: actionCount,
              label: this.label,
              backgroundColor: '#FBD6E3',
              fill: false,
              barThickness: 20,
            },
          ],
        };
      },
    },
  };
</script>
<style scoped>
.alert-style {
  color: #C62828;
  text-align: center;
}
.my-card-title-style {
  color: #37474F;
  margin-left: 2px;
  margin-top: 10px;
  font-size: 18px;
}
.my-card-subtitle-style {
  color: #37474F;
  margin-left: 2px;
  margin-top: 7px;
  font-size: 14px;
}
.my-updation-text-style {
  color: #37474F;
  font-size: 12px;
}
</style>
