<template>
  <base-chart-card
    color="#C2185B"
  >
    <template v-slot:heading>
      <base-bar-chart
        v-if="showChart"
        :chart-data="dataCollection"
        :options="options"
        style="max-height: 150px;"
      />
    </template>
    <template v-slot:reveal-actions>
      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            color="info"
            icon
            v-on="on"
          >
            <v-icon
              color="info"
            >
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <span>Refresh</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            v-bind="attrs"
            light
            icon
            v-on="on"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>

        <span>Change Date</span>
      </v-tooltip>
    </template>

    <h3 class="my-card-title-style">
      {{ userActivityChartDescription.heading }}
    </h3>

    <p class="my-card-subtitle-style">
      {{ userActivityChartDescription.subHeading }}
    </p>
    <p
      v-if="noDataExist"
      class="alert-style"
    >
      No Data to Display.
    </p>
    <template v-slot:actions>
      <v-icon
        class="mr-1"
        small
        dense
      >
        mdi-clock-outline
      </v-icon>
      <span class="my-updation-text-style">Updated {{ userActivityChartDescription.updatedAt | diffHumans }}</span>
    </template>
  </base-chart-card>
</template>
<script>
  import moment from 'moment';
  import { isDataExist } from 'src/helpers/chart-data-helper';

  export default {
    name: 'UserActivityChart',
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
    },
    data: () => ({
      label: 'Banner Count',
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: '#FBD6E3',
            fontsize: 14,
          },
        },
        scales: {
          yAxes: [{
            ticks: {
              fontColor: '#FBD6E3',
              stepSize: 50,
            },
          }],
          xAxes: [{
            ticks: {
              fontColor: '#FBD6E3',
            },
          }],
        },
      },
    }),
    computed: {
      chartData () {
        return this.$store.getters['dashboard/getUserActivityChart'];
      },
      userActivityChartDescription () {
        return this.$store.getters['dashboard/getUserActivityChartDescription'];
      },
      dataExist () {
        return this.chartData.length > 0;
      },
      showChart () {
        return Object.keys(this.dataCollection).length > 0;
      },
      dataCollection () {
        if (this.dataExist === false) {
          return {};
        }
        return this.generateDataCollection();
      },
      noDataExist () {
        const dataExist = isDataExist(this.chartData);
        if (dataExist === true) {
          return false;
        }
        return true;
      },
    },
    mounted () {
      this.generateUserActivityChartDescription();
      this.generateUserActivityChart();
    },
    methods: {
      generateUserActivityChartDescription () {
        const showLastMonthFirstDay = moment().subtract(1, 'months').startOf('month').format('DD-MM-YYYY');
        const showLastMonthLastDay = moment().subtract(1, 'months').endOf('month').format('DD-MM-YYYY');
        this.$store.dispatch('dashboard/fetchUserActivityChartDescription', {
          heading: 'User Activity',
          subHeading: `User activity between ${showLastMonthFirstDay} to ${showLastMonthLastDay}`,
          updatedAt: moment(),
        });
      },
      generateUserActivityChart () {
        this.$store.dispatch('dashboard/fetchUserCreatedChart', {
          params: {
            from: moment().subtract(1, 'months').startOf('month').toISOString(),
            to: moment().subtract(1, 'months').endOf('month').toISOString(),
            eventType: 'created',
          },
          label: 'Created',
        });
        this.$store.dispatch('dashboard/fetchUserDeletedChart', {
          params: {
            from: moment().subtract(1, 'months').startOf('month').toISOString(),
            to: moment().subtract(1, 'months').endOf('month').toISOString(),
            eventType: 'deleted',
          },
          label: 'Deleted',
        });
        this.$store.dispatch('dashboard/fetchUserUpdatedChart', {
          params: {
            from: moment().subtract(1, 'months').startOf('month').toISOString(),
            to: moment().subtract(1, 'months').endOf('month').toISOString(),
            eventType: 'updated',
          },
          label: 'Updated',
        });
      },
      generateDataCollection () {
        const actionCount = [];
        const labels = [];
        for (const result of this.chartData) {
          labels.push(result.label);
          actionCount.push(result.count);
        }
        return {
          labels: labels,
          datasets: [
            {
              data: actionCount,
              label: this.label,
              backgroundColor: '#FBD6E3',
              fill: false,
              barThickness: 20,
            },
          ],
        };
      },
    },
  };
</script>
<style scoped>
.alert-style {
  color: #C62828;
  text-align: center;
}
.my-card-title-style {
  color: #37474F;
  margin-left: 7px;
  margin-top: 10px;
  font-size: 18px;
}
.my-card-subtitle-style {
  color: #37474F;
  margin-left: 7px;
  margin-top: 7px;
  font-size: 14px;
}
.my-updation-text-style {
  color: #37474F;
  font-size: 12px;
}
</style>
