const dashboardData = {
    banner: {
        dailySalesChart: {
            data: {
              labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
              series: [
                [12, 17, 7, 17, 23, 18, 38],
              ],
            },
            title: 'Daily Board Views',
            subtitle: 'increase in today\'s views',
          },
          downtime: 'Zero ',
          dataCompletedTasksChart: {
            data: {
              labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
              series: [
                [230, 750, 450, 300, 280, 240, 200, 190],
              ],
            },
            title: 'Board Health Status',
            subtitle: ' Downtime today ',
          },
          emailsSubscriptionChart: {
            data: {
              labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
              series: [
                [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],

              ],
            },
            title: 'Published Banners',
            subtitle: 'Including campaign banners',
          },
          tile1: {
            name: 'Active Banners',
            value: '5',
          },
          tile2: {
            name: 'Expired Banners',
            value: '2',
          },
          tile3: {
            name: 'Campaigns',
            value: '2',
          },
          tile4: {
            name: 'Issues',
            value: '0',
          },
    },
    swiftCheckIn: {
        dailySalesChart: {
            data: {
              labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
              series: [
                [12, 17, 7, 17, 23, 18, 38],
              ],
            },
            title: 'Daily site check-in/check-out stats ',
            subtitle: 'increase in today\'s site working hours',
          },
          downtime: 'Zero ',
          dataCompletedTasksChart: {
            data: {
              labels: ['6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm'],
              series: [
                [230, 750, 450, 300, 280, 240, 200, 190],
              ],
            },
            title: 'Hourly check-ins ',
            subtitle: 'No Check-in after 2 PM',
          },
          emailsSubscriptionChart: {
            data: {
              labels: ['Ja', 'Fe', 'Ma', 'Ap', 'May', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
              series: [
                [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],

              ],
            },
            title: 'Site checkin numbers',
            subtitle: 'Including staff, contractor and visitors',
        },
          tile1: {
            name: 'Active Check-ins',
            value: '51',
          },
          tile2: {
            name: 'Check-outs today',
            value: '25',
          },
          tile3: {
            name: 'Site Hours',
            value: '235',
          },
          tile4: {
            name: 'Issues',
            value: '0',
          },
    },
};

export default dashboardData;
