<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#00695C"
          icon="mdi-file-image-outline"
          title="Active Banners"
          :value="String(activeBanners.count)"
          sub-icon="mdi-clock-outline"
          :sub-text="activeBanners.updatedAt | diffHumans"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#01579B"
          icon="mdi-image-off-outline"
          title="Expired Banners"
          :value="String(expiredBanners.count)"
          sub-icon="mdi-clock-outline"
          :sub-text="expiredBanners.updatedAt | diffHumans"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#E65100"
          icon="mdi-calendar"
          title="Scheduled Banners"
          :value="String(scheduledBanners.count)"
          sub-icon="mdi-clock-outline"
          :sub-text="scheduledBanners.updatedAt | diffHumans"
        />
      </v-col>
      <v-col
        v-if="permissionCheck('read-support-ticket-list')"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#C62828"
          icon="mdi-face-agent"
          title="Support Tickets"
          :value="supportTickets.length.toString()"
          sub-icon="mdi-check"
          sub-icon-color="green"
          sub-text="chillax..."
        />
      </v-col>
      <v-col
        v-if="!permissionCheck('read-support-ticket-list')"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <base-material-stats-card
          color="#C62828"
          icon="mdi-face-agent"
          title="Support Tickets"
          value="--"
          sub-icon="mdi-check"
          sub-icon-color="green"
          sub-text="chillax..."
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import moment from 'moment';
  import global from 'src/mixins/global';

  export default {
    name: 'DashBoardStatusCount',
    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
    },
    mixins: [global],
    data () {
      return {};
    },
    computed: {
      activeBanners () {
        return this.$store.getters['banners/getActiveBanners'] ?? [];
      },
      expiredBanners () {
        return this.$store.getters['banners/getExpiredBanners'] ?? [];
      },
      scheduledBanners () {
        return this.$store.getters['banners/getScheduledBanners'] ?? [];
      },
      supportTickets () {
        return this.$store.getters['support/getFinalIssueList'];
      },
    },
    mounted () {
      this.generateBannerStatusCount();
      this.permissionCheck('read-support-ticket-list') && this.generateSupportTicketCount();
    },
    methods: {
      generateBannerStatusCount () {
        this.$store.dispatch('banners/fetchActiveBanners', {
          params: {
            active: moment().toISOString(),
          },
          updatedAt: moment(),
        });
        this.$store.dispatch('banners/fetchExpiredBanners', {
          params: {
            end: moment().toISOString(),
          },
          updatedAt: moment(),
        });
        this.$store.dispatch('banners/fetchScheduledBanners', {
          params: {
            start: moment().toISOString(),
          },
          updatedAt: moment(),
        });
      },
      async generateSupportTicketCount () {
        await this.$store.dispatch('support/fetchSupportTickets', {
          params: {
            orderBy: 'created_at',
          },
        });
      },
    },
  };
</script>
