<template>
  <div>
    <cycles-completed-banner-chart v-if="showHistoryReporting" />
    <user-activity-chart v-if="showCurrentReporting" />
  </div>
</template>
<script>
  import UserActivityChart from './charts/UserActivityChart.vue';
  import CyclesCompletedBannerChart from './charts/CyclesCompletedBannerChart.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';

  export default {
    name: 'DashBoardLeftChart',
    components: {
      UserActivityChart,
      CyclesCompletedBannerChart,
    },
    data () {
      return {
        helper: new ModuleHelper(),
      };
    },
    computed: {
      showCurrentReporting () {
        return this.helper.isSubModuleExist(Constants.MODULE_DASHBOARD, Constants.SUB_MODULE_CURRENT_REPORTING);
      },
      showHistoryReporting () {
        return this.helper.isSubModuleExist(Constants.MODULE_DASHBOARD, Constants.SUB_MODULE_HISTORY_REPORTING);
      },
    },
  };
</script>
